import React, { Fragment, useState } from "react";
import loadable from "@loadable/component";
import "./styles.scss";
import { useStaticQuery, graphql } from "gatsby";
import firebase from '../../firebase';
import { filterImagesFluid } from "../../util/filterImages";
import { Row, Col } from "antd/lib";


import useWindowSize from "../../hooks/useWindowsSize";
import Contacto from '../../components/sections/contacto';
import { FirebaseContext } from '../../firebase';

const BgImage = loadable(() => import("../../components/sections/Hero/BackgroundImage"));
// const TestimoniesCourse = loadable(() => import("../../components/sections/Testimonies"));
const Professional = loadable(() => import("./professional"));
const Modality = loadable(() => import("./modality"));
const Benefits = loadable(() => import("./benefits"));
const Program = loadable(() => import("./program"));
// const LongDescription = loadable(() => import("./longDescription"));
const CursoNavBar = loadable(() => import("../../components/sharded/CursoNavBar"));
const CourseMenu = loadable(() => import("../../components/sharded/CursoNavBar/menuCurso"));
const BurguerMenu = loadable(() => import("../../components/sharded/mainNavbar/burgerMenu"));
const Section = loadable(() => import("../../components/sharded/section"));
const Layout = loadable(() => import("../../components/layout/layout"));

const CourseTemplate = ({ pageContext }) => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowSize();
  const {
    subtitle,
    name,
    professional,
    program,
    longDescription,
    modality,
    menu,
    benefits,
    slung
  } = pageContext;
  console.log(pageContext);
  console.log(slung);
  const data = useStaticQuery(graphql`
    query MyQuery {
      allTestimonies {
        edges {
          node {
            testimonie
            profession
            photo
            id
            fullName
          }
        }
      }
      allImages {
        edges {
          node {
            name
            localImage {
              childImageSharp {
                fluid {
                 
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  console.log(data.allImages);
  const images = data.allImages.edges;
  console.log(images);
  const imageCourse = filterImagesFluid(images, `${slung}-main`)[0].node.localImage
    .childImageSharp.fluid;
  const imageBenefits = filterImagesFluid(images, `${slung}-professional`)[0].node
    .localImage.childImageSharp.fluid;

  console.log(longDescription.detail);
  const imageModality = filterImagesFluid(images, `${slung}-modality`)[0].node.localImage
    .childImageSharp.fluid;
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Layout>

      <FirebaseContext.Provider
        value={{
          firebase,
        }}
      >


        {width > 968 ? (
          <CursoNavBar open={open} setOpen={setOpen} menu={menu}></CursoNavBar>
        ) : (
            <Fragment>
              <BurguerMenu open={open} handleClick={handleClick}></BurguerMenu>
              {open && (
                <CourseMenu menu={menu} name={name} handleClick={handleClick}></CourseMenu>
              )}
            </Fragment>
          )}


        <Section id="descripcion" >
          <BgImage bg={imageCourse}>
            <Row align="middle" justify="center">
              <Col xs={24}>
                <div className="header_container">
                  <h1 className="header_title">{name}</h1>
                  <p className="header_subtitle">{subtitle}</p>
                  <div className="header_summary" dangerouslySetInnerHTML={{ __html: longDescription.detail }}>
                  </div>
                </div>
              </Col>
            </Row>
          </BgImage>
        </Section>
        {/* <LongDescription longDescription={longDescription}></LongDescription> */}
        <Benefits benefits={benefits}></Benefits>
        <BgImage bg={imageModality}>
          <Modality modality={modality}></Modality>
        </BgImage>
        <Program program={program} />
        <BgImage bg={imageBenefits}>
          <Professional professional={professional}></Professional>
        </BgImage>
        {/*  <div className="background-wrapper-only-testimonies">
          <TestimoniesCourse allTestimonies={data.allTestimonies}></TestimoniesCourse>
        </div>
        {/* <Footer /> */}
        <Contacto from={name} title="reserva tu lugar" subtitle={`Envíanos tu consulta para que podamos informarte más acera del curso de ${name}`} ></Contacto>



      </FirebaseContext.Provider>
    </Layout >
  );
};

export default CourseTemplate;




































